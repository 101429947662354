import { useEffect, useState } from "react"
import styleClasses from "./UsersStatistics.module.css"
import Api from "../../api/Api"
import { formatMonth, formatOnlyDate, getDateRange } from "../../utils/date"
import { Line } from "react-chartjs-2";
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

Chart.register(CategoryScale);

export const UsersStatistics = () => {
    const [statistics, setStatistics] = useState([])
    useEffect(() => {
        Api.getUsersStatistics().then(setStatistics).catch(console.error)
    }, [])

    const now = new Date()
    const dateFrom = new Date().setMonth(now.getMonth() - 1);
    const rDateRange = getDateRange(dateFrom, now)
    const fDateRange = rDateRange.map(formatOnlyDate)
    let lastValue = statistics[0]?.count
    const values = fDateRange.map((date) => {
        const stat = statistics.find((v) => v.date === date)
        if (stat?.count) {
            lastValue = stat.count
        }
        return stat?.count || lastValue
    })

    const lineChartData = {
        labels: fDateRange.map(formatMonth),
        datasets: [
          {
            data: values,
            label: "Пользователи",
            borderColor: "#3333ff",
          }
        ]
      };

    return (<div className={styleClasses.container}>
       <Line
        type="line"
        width={600}
        height={400}
        options={{
            title: {
                display: true,
                text: "COVID-19 Cases of Last 6 Months",
                fontSize: 20
            },
            legend: {
            display: false,
            position: "top"
            }
        }}
        data={lineChartData}
        />
    </div>)
}
