import { useState } from "react"
import styleClasses from "./ModalMailing.module.css"
import { MyButton } from "../UI/MyButton/MyButton"
import Api from "../../api/Api"

export const ModalMailing = ({ onClose, selectedUserIds }) => {
    const [message, setMessage] = useState('')
    const onSend = () => {
        if (window.confirm(`Отправить ${selectedUserIds.length} пользователям сообщение\n\n${message}`)) {
            Api.startMailing({
                message,
                userIds: selectedUserIds,
            })
            onClose()
        }
    }
    return <div className={styleClasses.container}>
        <div className={styleClasses.title}>Сообщение</div>
        <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={styleClasses.message}
        />
        <MyButton onClick={onSend}>Отправить</MyButton>
    </div>
        
}
