import { useState } from "react"
import { MyButton } from "../UI/MyButton/MyButton"
import styleClasses from "./NewNews.module.css"
import { MyInput } from "../UI/MyInput/MyInput"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Api from "../../api/Api";
import {useNavigate} from 'react-router-dom';
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";

export const NewNews = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [title, setTitle] = useState('')

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    // const onEditorStateChange = (editorState) => {
    //    setState({
    //       editorState,
    //     });
    // }
    const navigate = useNavigate();

    return (<div className={styleClasses.container}>
        <label>Заголовок</label>
        <MyInput
            value={title}
            className={styleClasses.input}
            onChange={(e) => setTitle(e.target.value)}
        />
        <label>Содержимое</label>
        <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName={styleClasses.wrapper}
            editorClassName={styleClasses.editor}
            onEditorStateChange={onEditorStateChange}
        />
        <MyButton onClick={()=> {
            Api.createNews({
                title,
                content: convertToHTML(editorState.getCurrentContent()),
            }).then(() => navigate('/news')).catch(console.error)
        }}>Сохранить</MyButton>
    </div>)
}
