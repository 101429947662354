import { useEffect, useState } from "react"
import { MyButton } from "../UI/MyButton/MyButton"
import { MyCheckBox } from "../UI/MyCheckBox/MyCheckBox"
import styleClasses from "./UserList.module.css"
import Api from "../../api/Api"
import { MyModal } from "../UI/MyModal/MyModal"
import { ModalUser } from "../ModalUser/ModalUser"
import { formatDate } from "../../utils/date"
import { ModalMailing } from "../ModalMailing/ModalMailing"

export const UserList = () => {
    const [users, setUsers] = useState([])

    useEffect(() => {
        Api.getUsers().then(setUsers).catch(console.error)
    }, [])

    const columns = [
        {
            name: '',
            field: 'select',
            width: 25
        },
        {
            name: 'ID',
            field: 'id',
            width: 50
        },
        {
            name: 'Имя Фамилия',
            field: 'name',
            width: 150
        },
        {
            name: 'Баланс',
            field: 'balance',
            width: 100
        },
        {
            name: 'Email',
            field: 'email',
            width: 200
        },
        {
            name: 'Юзернейм',
            field: 'username',
            width: 100
        },
        {
            name: 'Начало использования',
            field: 'createdAt',
            width: 150,
            type: 'Date'
        },
        {
            name: 'Админ',
            field: 'isAdmin',
            width: 50
        },
        {
            name: 'Подтв. Email',
            field: 'isConfirm',
            width: 50
        },
        {
            name: 'В бане',
            field: 'isBanned',
            width: 50
        },
    ]

    const [selectedUserIds, setSelectedUserIds] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [isVisibleMailingWindow, setIsVisibleMailingWindow] = useState(false)
    const isSelectedAll = selectedUserIds?.length === users?.length
    return (<div className={styleClasses.container}>
        <MyModal
            isVisible={!!selectedUser}
            onClose={()=>setSelectedUser(null)}
        >
            <ModalUser
                user={selectedUser}
                updateUser={(user) => {
                    Api.updateUser(user.id, { balance: user.balance, isAdmin: user.isAdmin, isBanned: user.isBanned, isConfirm: user.isConfirm }).catch(console.error)
                }}
                setUsers={() => {
                    setUsers([...users])
                }}
                refreshUsers={
                    () => {
                        Api.getUsers().then((users) => {
                            setUsers(users)
                            setSelectedUser(users.find(u => u.id === selectedUser.id))
                        }).catch(console.error)
                        
                    }
                }
                onClose={() => ()=>setSelectedUser(null)}
                users={users}
            />
        </MyModal>
        <MyModal
            isVisible={!!isVisibleMailingWindow}
            onClose={()=>setIsVisibleMailingWindow(false)}
        >
            <ModalMailing
                onClose={()=>setIsVisibleMailingWindow(false)}
                selectedUserIds={selectedUserIds}
            />
        </MyModal>

        <div className={styleClasses.header}>
        <div className={styleClasses.title}>Пользователи</div>
            <div className={styleClasses.keyboard}>
                <MyButton disabled={selectedUserIds.length === 0} onClick={
                    ()=>{setIsVisibleMailingWindow(true)}
                }>Рассылка</MyButton>
            </div>
        </div>
        
        <table className={styleClasses.content}>
            <tr className={styleClasses.title_row}>
                {
                    columns.map(c => (
                        <td className={styleClasses.title_row_item} style={{width: c.width}}>
                            {
                            c.field === 'select'
                            ? <MyCheckBox
                                onClick={(e) => {
                                    if (!e.target.checked) {
                                        setSelectedUserIds([])
                                        return
                                    }
                                    setSelectedUserIds(users.map((u) => u.id))
                                }}
                                onChange={() => {}}
                                checked={isSelectedAll}
                            />
                            : c.name}
                        </td>
                    ))
                }
            </tr>
            {
                users.map(u => (
                    <tr className={styleClasses.row_item} onClick={() => setSelectedUser(u)}>
                        {
                        columns.map(c => {
                            let value = '–'
                            if (u[c.field] !== undefined || u[c.field] !== null) {
                                value = u[c.field]
                            }
                            if (c.field === 'select') {
                                return <MyCheckBox
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => {
                                        selectedUserIds.includes(u.id)
                                        ? setSelectedUserIds([...selectedUserIds.filter(id => id !== u.id)])
                                        : setSelectedUserIds([...selectedUserIds, u.id])
                                    }
                                }
                                checked={selectedUserIds.includes(u.id)}
                                />
                            }
                            if (typeof  value === 'boolean') {
                                value = value ? '+' : '–'
                            }

                            if (c.type === 'Date') {
                                value = formatDate(value)
                            }

                            return <td className={styleClasses.cell} style={{width: c.width}}>{value}</td>
                        })}
                    </tr>
                ))
            }
        </table>
    </div>)
}
