import styleClasses from "./LogList.module.css"

export const LogLevel = ({ level, isSelected, onClick }) => {
    const classes = [styleClasses.level]
    if (isSelected) {
        classes.push(styleClasses.selected)
    }

    return (<span onClick={onClick} className={classes.join(' ')}>{level}</span>)
}
