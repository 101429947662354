import styleClasses from "./MyButton.module.css"

export const MyButton = ({children, className, disabled, img, ...props }) => {
    const classes = [styleClasses.button, className]
    if (disabled) {
        classes.push(styleClasses.disabled)
    }
    if (img) {
        return <div className={classes.join(' ')} {...props}>
            <img alt="" src={img} className={styleClasses.image} />
        </div>
    }
    return <button className={classes.join(' ')} {...props}>{children}</button>
}