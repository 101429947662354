import { useEffect, useState } from "react"
import styleClasses from "./LogList.module.css"
import Api from "../../api/Api"
import { formatDate } from "../../utils/date"
import { CFormSelect } from '@coreui/react';
import { DateRange } from 'react-date-range';
import './calendar.css'
import { LogLevel } from "./LogLevel";

const allLevels = [ "debug", "info", "warning", "error" ]

export const LogList = () => {
    const [offset, setOffset] = useState(0)
    const [logs, setLogs] = useState([])
    const [levels, setLevels] = useState(allLevels)
    const [components, setComponents] = useState([])
    const [selectedComponents, setSelectedComponents] = useState([])
    const [dateTo, setDateTo] = useState(new Date())
    const [dateFrom, setDateFrom] = useState(new Date(new Date().setDate(new Date().getDate() - 30)))

    const [isVisibleCalendar, setIsVisibleCalendar] = useState(false)

    useEffect(() => {
        Api.getLogComponents().then(setComponents).catch(console.error)
    }, [])

    useEffect(() => {
        Api.getLogs({ levels, components: selectedComponents, dateTo, dateFrom, offset }).then(setLogs).catch(console.error)
    }, [levels, components, dateTo, dateFrom, selectedComponents, offset])

    return (<div className={styleClasses.container}>
                <div className={styleClasses.title}>Логи</div>
                <div
                    className={ isVisibleCalendar ? styleClasses.calendarOut : '' }
                    onClick={()=> { if (isVisibleCalendar) { setIsVisibleCalendar(false) } }}
                ></div>
                <div className={styleClasses.filters}>
                    <div className={styleClasses.levels}>
                        {
                            allLevels.map((level) => <LogLevel
                                level={level}
                                isSelected={ levels.includes(level) }
                                onClick={() => {
                                    if (levels.includes(level)) {
                                        setLevels([...levels.filter(l => l !== level)])
                                    } else {
                                        setLevels([...levels, level])
                                    }
                                }}
                                />
                            )
                        }
                    </div>
                    <div className={styleClasses.filter}>
                        <CFormSelect
                            aria-label="Компонент"
                            options={[
                                'Выберите компонент',
                                ...components.map((c) => ({ label: c, value: c })),
                            ]}
                            onChange={(e) => {
                                const value = e.target.value
                                if (value === "Выберите компонент") {
                                    setSelectedComponents([])
                                    return
                                }
                                setSelectedComponents([value])
                            }}
                        />
                    </div>
                    <div className={styleClasses.filter}>
                        <div
                            onClick={() =>{ if (!isVisibleCalendar) { setIsVisibleCalendar(true) } }}
                        >
                            <DateRange
                                className={ isVisibleCalendar ? '' : 'hidden'}
                                ranges={[{startDate: dateFrom, endDate: dateTo}]}
                                onChange={(v) => {
                                    setDateFrom(v?.range1?.startDate)
                                    setDateTo(v?.range1?.endDate)
                                }}
                            />
                        </div>
                    </div>
                </div>
            <div className={styleClasses.list}>
                <table className={styleClasses.table}>
                    <tr className={styleClasses.titleRow}>
                        <td className={styleClasses.cell}>Компонент</td>
                        <td className={styleClasses.cell}>Дата</td>
                        <td className={styleClasses.cell}>Сообщение</td>
                        <td className={styleClasses.cell}>Уровень</td>
                        <td className={styleClasses.cell}>Информация</td>
                    </tr>
                    {
                        logs.map((l) => (
                            <tr>
                                <td className={styleClasses.cell}>{l.component}</td>
                                <td className={styleClasses.cell}>{formatDate(l.createdAt)}</td>
                                <td className={styleClasses.cell}>{l.message}</td>
                                <td className={styleClasses.cell}>{l.level}</td>
                                <td className={styleClasses.cell}>{JSON.stringify(l.data)}</td>
                            </tr>
                        ))
                    }
                </table>
            </div>
    </div>)
}
