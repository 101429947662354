import { useEffect, useState } from "react"
import styleClasses from "./ProcessList.module.css"
import Api from "../../api/Api"
import { ProcessListItem } from "../ProcessListItem/ProcessListItem"

export const ProcessList = () => {
    const [processes, setProcesses] = useState([])

    const reload = () => {
        Api.getProcesses().then(setProcesses).catch(console.error)
    }

    useEffect(() => {
        setInterval(reload, 10000)
        reload()
    }, [])

    return (<div className={styleClasses.container}>
        { processes.map(process => <ProcessListItem process={process} reloadProcesses={reload} />) }
    </div>)
}
