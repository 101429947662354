import { useEffect, useState } from "react"
import styleClasses from "./PaymentsStatistics.module.css"
import Api from "../../api/Api"
import { formatMonth, formatOnlyDate, getDateRange } from "../../utils/date"
import { Bar, Line } from "react-chartjs-2";
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

Chart.register(CategoryScale);

export const PaymentsStatistics = () => {
    const [statistics, setStatistics] = useState([])
    useEffect(() => {
        Api.getPaymentsStatistics().then(setStatistics).catch(console.error)
    }, [])

    const now = new Date()
    const dateFrom = new Date().setMonth(now.getMonth() - 1);
    // const fDateFrom = formatOnlyDate(dateFrom);
    const rDateRange = getDateRange(dateFrom, now)
    const fDateRange = rDateRange.map(formatOnlyDate)
    const values = fDateRange.map((date) => {
        const stat = statistics.find((v) => v.date === date)
        return stat?.sum || 0
    })
    const barChartData = {
        labels: fDateRange.map(formatMonth),
        datasets: [
          {
            data: values,
            label: "Платежи",
            borderColor: "#3333ff",
            backgroundColor: "rgba(0, 0, 255, 0.5)",
            fill: true,
          },
        ]
      };

    return (<div className={styleClasses.container}>
       <Bar
        type="bar"
        width={600}
        height={400}
        options={{
            title: {
                display: true,
                text: "COVID-19 Cases of Last 3 Months",
                fontSize: 15
            },
            legend: {
                display: true, //Is the legend shown?
                position: "top" //Position of the legend.
            }
        }}
        data={barChartData}
        />
    </div>)
}
