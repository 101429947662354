import styleClasses from "./MenuItem.module.css"
import { useNavigate } from 'react-router-dom';

export const SubMenuItem = ({ name, link, onCloseMenu }) => {
    const navigate = useNavigate();

    const isSelectedItem = window.location.pathname.includes(link)

    return (<div className={styleClasses.subitem} onClick={() => {
        navigate(link);
        onCloseMenu()
    }}>
        <div className={styleClasses.subtitle}>{name}</div>
    </div>)
}
