import Api from "../../api/Api";
import { MyButton } from "../UI/MyButton/MyButton";
import styleClasses from "./ProcessListItem.module.css"

export const ProcessListItem = ({ process, reloadProcesses }) => {
    const nameMapping = {
        bot: "Бот",
        etl_ad: "ETL парсера",
        hook_server: "Хук релизов",
        image_proxy: "Прокси изображений",
        server: "Сервер",
        run_ad_parser_v2: "Парсер объявлений",
        run_search_parser_v2: "Парсер поисковой выдачи"
    }

    const statusMapping = {
        online: 'Онлайн',
        stopped: 'Остановлен',
        stopping: 'Останавливается',
        launching: 'Запускается',
        errored: 'Ошибка',
        one_launch: 'Одиночный запуск',
        reloaded: 'Перезагружен',
        restarting: 'Перезапускается',
        orphan: 'Потерянный'
    };

    const classes = [styleClasses.card]
    if (process.status !== "online") {
        classes.push(styleClasses.red)
    }

    return (<div className={classes.join(' ')}>
        <div className={styleClasses.mainInfo}>
            <div className={styleClasses.name}>
                { nameMapping[process.name] || process.name }
            </div>
            <div className={styleClasses.params}>
                <div className={styleClasses.field_param}>
                    CPU { process.cpu }
                </div>
                <div className={styleClasses.field_param}>
                    Memory: { process.memory }
                </div>
                <div className={styleClasses.field_param}>
                    Перезапусков: { process.restarts }
                </div>
            </div>
        </div>
        <div className={styleClasses.status}>
            <div className={styleClasses.status_item}>
            { statusMapping[process.status] || process.status }
            </div>
            <div className={styleClasses.status_item}>
                <MyButton img={"/icons/ic32-rotate.png"} onClick={() => {
                    Api.restartProcess(process.id).then(reloadProcesses)
                }}></MyButton>
            </div>
        </div>
    </div>)
}
