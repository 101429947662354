import styleClasses from './MyModal.module.css'

export const MyModal = ({ children, isVisible, onClose, ...props }) => {
    if (!isVisible) {
        return null
    }

    return <div className={styleClasses.container} onClick={onClose}>
        <div className={styleClasses.window} onClick={(e) => e.stopPropagation()}>
            <div className={styleClasses.content} {...props}>
                {children}
            </div>
        </div>
    </div>
}