import axios from 'axios'

export default class Api {

    static apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080'

    static async getLogs({ levels, components, dateTo, dateFrom, offset }) {
        const token = this.getBasicToken()
        try {
            const params = {
                levels: levels.join(','),
                components: components.join(','),
                from: dateFrom.toISOString(),
                to: dateTo.toISOString(),
                offset,
                limit: 1000,
            }
            const res = await axios.get(`${this.apiUrl}/api/admin/logs`, {
                headers: { 'Authorization': token },
                params,
            })
            return res.data
        } catch(e) {
            if (e?.request?.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async getLogComponents() {
        const token = this.getBasicToken()
        try {
            const res = await axios.get(`${this.apiUrl}/api/admin/logs/components`, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async updateUser(id, data) {
        const token = this.getBasicToken()
        try {
            await axios.patch(`${this.apiUrl}/api/admin/user/${id}`,data, {
                headers: { 'Authorization': token },
            })
            return
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async getUsers() {
        const token = this.getBasicToken()
        try {
            const res = await axios.get(`${this.apiUrl}/api/admin/users`, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async startMailing(data) {
        const token = this.getBasicToken()
        try {
            const res = await axios.post(`${this.apiUrl}/api/admin/mailing`, data, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async createNews(data) {
        const token = this.getBasicToken()
        try {
            await axios.post(`${this.apiUrl}/api/admin/news`, data, {
                headers: { 'Authorization': token }
            })
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async deleteNews(id) {
        const token = this.getBasicToken()
        try {
            await axios.delete(`${this.apiUrl}/api/admin/news/${id}`, {
                headers: { 'Authorization': token }
            })
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async getNews() {
        const token = this.getBasicToken()
        try {
            const res = await axios.get(`${this.apiUrl}/api/admin/news`, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async restartProcess(id) {
        const token = this.getBasicToken()
        try {
            const res = await axios.post(`${this.apiUrl}/api/admin/process/${id}/restart`, null, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async getProcesses() {
        const token = this.getBasicToken()
        try {
            const res = await axios.get(`${this.apiUrl}/api/admin/processes`, {
                headers: { 'Authorization': token }
            })
            return res.data.processes
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async getPayments() {
        const token = this.getBasicToken()
        try {
            const res = await axios.get(`${this.apiUrl}/api/admin/payments`, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async getUsersStatistics() {
        const token = this.getBasicToken()
        try {
            const res = await axios.get(`${this.apiUrl}/api/admin/statistics/users`, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async getFeaturesStatistics() {
        const token = this.getBasicToken()
        try {
            const res = await axios.get(`${this.apiUrl}/api/admin/statistics/features`, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static async getPaymentsStatistics() {
        const token = this.getBasicToken()
        try {
            const res = await axios.get(`${this.apiUrl}/api/admin/statistics/payments`, {
                headers: { 'Authorization': token }
            })
            return res.data
        } catch(e) {
            if (e.request.status === 401) {
                this.handleUnauthorizedError()
            }
            throw e
        }
    }

    static getCredentials() {
        const login = window.localStorage.getItem('login')
        const password = window.localStorage.getItem('password')
        if (!login || !password) {
            window.location.href = '/auth'
            return
        }
        return { login, password }
    }

    static getBasicToken() {
        const creds = this.getCredentials()
        if (!creds) {
            return ''
        }

        const token = btoa(creds.login + ':' + creds.password);
        return 'Basic ' + token;
    }

    static handleUnauthorizedError() {
        window.localStorage.setItem('login', '')
        window.localStorage.setItem('password', '')
        window.location.href = '/auth'
        return
    }
}