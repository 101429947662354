import { useEffect, useState } from "react"
import styleClasses from "./ReferralUserList.module.css"
import Api from "../../api/Api"
import { formatDate } from "../../utils/date"
import { ReferralUserListItem } from "../ReferralUserListItem/ReferralUserListItem"

export const ReferralUserList = () => {
    const [users, setUsers] = useState([])

    useEffect(() => {
        Api.getUsers().then(setUsers).catch(console.error)
    }, [])

    const referralUsers = users
        .filter((user) => user.countReferrals > 0)
        .sort((a,b) => b.countReferrals - a.countReferrals)
        .map((user) => ({
            ...user,
            invitedUsers: users.filter((u) => u.referralUserId === user.id)
        }))

    return (<div className={styleClasses.container}>
        <div className={styleClasses.header}>
            <div className={styleClasses.title}>Пользователи рефералы</div>
        </div>
        <div className={styleClasses.list}>
            {
                referralUsers.map((user)=><ReferralUserListItem user={user} />)
            }
        </div>
    </div>)
}
