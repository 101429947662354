import { useState } from 'react'
import { MyButton } from '../UI/MyButton/MyButton'
import { MyInput } from '../UI/MyInput/MyInput'
import styleClasses from './AuthPage.module.css'
import { useNavigate } from 'react-router-dom'

export const AuthPage = () => {
    const navigate = useNavigate();
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const auth = () => {
        window.localStorage.setItem('login', login)
        window.localStorage.setItem('password', password)
        navigate('/users', { replace: true })
    }

    return (
        <div className={styleClasses.window}>
            <div className={styleClasses.container}>
                <div className={styleClasses.title}>Вход</div>
                <MyInput
                    placeholder='Логин'
                    value={login}
                    onChange={(e) => {setLogin(e.target.value)}}
                    className={styleClasses.input}
                />
                <MyInput
                    placeholder='Пароль'
                    type='password'
                    value={password}
                    onChange={(e) => {setPassword(e.target.value)}}
                    className={styleClasses.input}
                />
                <MyButton onClick={auth}>Войти</MyButton>
            </div>
        </div>
    )
}