import styleClasses from "./MenuItem.module.css"
import { useNavigate } from 'react-router-dom';
import { SubMenuItem } from "./SubMenuItem";

export const MenuItem = ({name, icon, link, children, onCloseMenu }) => {
    const navigate = useNavigate();
    const isSelectedItem = window.location.pathname.includes(link)
    return (
    <div className={styleClasses.container}>
        <div className={styleClasses.item} onClick={() => {
            navigate(link)
            onCloseMenu()
        }}>
            <img alt="" className={styleClasses.icon} src={icon}/>
            <div className={styleClasses.title}>{name}</div>
        </div>
        {
            children && isSelectedItem ?
            <div className={styleClasses.subitems}>
                {
                    children.map((child) => <SubMenuItem name={child.name} link={child.link} onCloseMenu={onCloseMenu} />)
                }
            </div>
            : null
        }
    </div>)
}
