import { useEffect, useState } from "react"
import styleClasses from "./FeaturesStatistics.module.css"
import Api from "../../api/Api"
import { formatMonth, formatOnlyDate, getDateRange } from "../../utils/date"
import { Bar, Line } from "react-chartjs-2";
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

Chart.register(CategoryScale);

export const FeaturesStatistics = () => {
    const [statistics, setStatistics] = useState([])
    useEffect(() => {
        Api.getFeaturesStatistics().then(setStatistics).catch(console.error)
    }, [])

    const now = new Date()
    const dateFrom = new Date().setMonth(now.getMonth() - 1);
    // const fDateFrom = formatOnlyDate(dateFrom);
    const rDateRange = getDateRange(dateFrom, now)
    const fDateRange = rDateRange.map(formatOnlyDate)
    const autoloaderValues = fDateRange.map((date) => {
        const stat = statistics?.autoloader?.find((v) => v.date === date)
        return stat?.count || 0
    })
    const randomizerValues = fDateRange.map((date) => {
        const stat = statistics?.randomizer?.find((v) => v.date === date)
        return stat?.count || 0
    })
    const parserValues = fDateRange.map((date) => {
        const stat = statistics?.parser?.find((v) => v.date === date)
        return stat?.count || 0
    })

    const barChartData = {
        labels: fDateRange.map(formatMonth),
        datasets: [
          {
            data: autoloaderValues,
            label: "Автозагрузка",
            borderColor: "#3333ff",
            backgroundColor: "rgba(0, 0, 255, 0.5)",
            fill: true,
          },
          {
            data: randomizerValues,
            label: "Рандомайзер",
            borderColor: "#ff3333",
            backgroundColor: "#ff3333",
            fill: true,
          },
          {
            data: parserValues,
            label: "Парсер",
            borderColor: "#6FE561",
            backgroundColor: "#6FE561",
            fill: false,
          },
        ]
      };

    return (<div className={styleClasses.container}>
       <Bar
        type="bar"
        width={600}
        height={400}
        options={{
            title: {
                display: true,
                text: "COVID-19 Cases of Last 3 Months",
                fontSize: 15
            },
            legend: {
                display: true, //Is the legend shown?
                position: "top" //Position of the legend.
            }
        }}
        data={barChartData}
        />
    </div>)
}
