import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { MainLayout } from "../layout/MainLayout";
import { UserList } from "../components/UserList/UserList";
import { AuthPage } from "../components/AuthPage/AuthPage";
import { ReferralUserList } from "../components/ReferralUserList/ReferralUserList";
import { ProcessList } from "../components/ProcessList/ProcessList";
import { PaymentList } from "../components/PaymentList/PaymentList";
import { StatisticsPage } from "../components/StatisticsPage/StatisticsPage";
import { LogList } from "../components/LogList/LogList";
import { MainPage } from "../components/MainPage/MainPage";
import { NewsList } from "../components/NewsList/NewsList";
import { NewNews } from "../components/NewNews/NewNews";

const router = createBrowserRouter([
  {
    path: "/users",
    element: (
      <MainLayout><UserList /></MainLayout>
    ),
  },
  {
    path: "/users/referrals",
    element: (
      <MainLayout><ReferralUserList /></MainLayout>
    ),
  },
  {
    path: "/logs",
    element: (
      <MainLayout><LogList /></MainLayout>
    ),
  },
  {
    path: "/news",
    element: (
      <MainLayout><NewsList /></MainLayout>
    ),
  },
  {
    path: "/news/new",
    element: (
      <MainLayout><NewNews /></MainLayout>
    ),
  },
  {
    path: "/statistics",
    element: (
      <MainLayout><StatisticsPage /></MainLayout>
    ),
  },
  {
    path: "/payments",
    element: (
      <MainLayout><PaymentList /></MainLayout>
    ),
  },
  {
    path: "/auth",
    element: (
      <AuthPage />
    ),
  },
  {
    path: "/remote",
    element: (
      <MainLayout><ProcessList /></MainLayout>
    ),
  },
  {
    path: "/",
    element: (
      <MainLayout><MainPage /></MainLayout>
    ),
  },

]);

const Router = () => (<RouterProvider router={router} />)

export default Router