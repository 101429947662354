import { MenuItem } from "../MenuItem/MenuItem"
import styleClasses from "./Menu.module.css"

export const Menu = ({ onCloseMenu }) => {
    const values = [
        {
            name: 'Статистика',
            icon: '/icons/ic32-chart-bar.png',
            link: '/statistics'
        },
        {
            name: 'Пользователи',
            icon: '/icons/ic32-user.png',
            link: '/users',
            children: [
                {
                    name: 'Все пользователи',
                    link: '/users',  
                },
                {
                    name: 'Рефералы',
                    link: '/users/referrals',  
                },
            ]
        },
        {
            name: 'Логи',
            icon: '/icons/ic32-bug.png',
            link: '/logs'
        },
        {
            name: 'Новости',
            icon: '/icons/ic20-email.png',
            link: '/news',
            children: [
                {
                    name: 'Все новости',
                    link: '/news',  
                },
                {
                    name: 'Создать новость',
                    link: '/news/new',  
                },
            ]
        },
        {
            name: 'Платежи',
            icon: '/icons/ic32-credit-card.png',
            link: '/payments'
        },
        {
            name: 'Управление',
            icon: '/icons/ic32-alert.png',
            link: '/remote'
        },
    ]

    return <div className={styleClasses.menu}>{ values.map((v) => (<MenuItem name={v.name} icon={v.icon} link={v.link} onCloseMenu={onCloseMenu} children={v.children} />)) }</div>
}
