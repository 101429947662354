import * as DateFns from 'date-fns';

export function formatDate(date) {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
    };
    const result = new Date(date).toLocaleString("ru", options)
    return result
}

export function formatMonth(date) {
    const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт','Ноя','Дек'];

    const parts = date.split('-');
    const day = Number(parts[2]);
    const monthIndex = Number(parts[1]) - 1; 
        
    return `${day} ${months[monthIndex]}`;
}

export function formatOnlyDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
  
   return `${year}-${month}-${day}`;
}

export function getDateRange(start, end) {
    return DateFns.eachDayOfInterval({ start: new Date(start), end: new Date(end) });
}