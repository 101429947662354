import { formatDate } from "../../utils/date"
import styleClasses from "./ModalUser.module.css"
import { MyInput } from "../UI/MyInput/MyInput"
import { MyCheckBox } from "../UI/MyCheckBox/MyCheckBox"
import { MyButton } from "../UI/MyButton/MyButton"

export const ModalUser = ({ user, onClose, users, updateUser, setUsers, refreshUsers }) => {

    const originUser = user.referralUserId ? users.find((u) => u.id === user.referralUserId) : null
    return (<div className={styleClasses.container}>
        <div className={styleClasses.title}>Пользователь</div>
        <div className={styleClasses.field}>
            ID: {user.id}
        </div>
        <div className={styleClasses.field}>
            Имя: {user.name}
        </div>
        <div className={styleClasses.field}>
            Юзернейм: {user.username || '-'}
        </div>
        <div className={styleClasses.field}>
            Авторизовался: {formatDate(user.createdAt)}
        </div>
        <div className={styleClasses.field}>
            Привел пользователей: {user.countReferrals}
        </div>
        <div className={styleClasses.field}>
            Реферал: {originUser ? `${originUser.id} / ${originUser.name} / ${originUser.username}` : '-'}
        </div>
        <div className={[styleClasses.field, styleClasses.fieldInpt].join(' ')}>
            <label htmlFor="balance">Баланс</label>
            <MyInput
                id="balance"
                value={user.balance}
                onChange={(e) => {
                    const value = Number(e.target.value)
                    if (!value && value !== 0 || value < 0) {
                        return
                    }

                    user.balance = value
                    setUsers()
                }}
            />
        </div>

        <div className={[styleClasses.field, styleClasses.fieldInpt].join(' ')}>
            <label htmlFor="is_admin">Админ</label>
            <MyCheckBox
                id="is_admin"
                checked={user.isAdmin}
                onChange={(e) => {
                    user.isAdmin = e.target.checked
                    setUsers()
                }} />
        </div>

        <div className={[styleClasses.field, styleClasses.fieldInpt].join(' ')}>
            <label htmlFor="is_banned">В бане</label>
            <MyCheckBox
                id="is_banned"
                checked={user.isBanned}
                onChange={(e) => {
                    user.isBanned = e.target.checked
                    setUsers()
                }} />
        </div>

        <div className={[styleClasses.field, styleClasses.fieldInpt].join(' ')}>
            <label htmlFor="is_confirm">Подтвержденный Email</label>
            <MyCheckBox
                id="is_confirm"
                checked={user.isConfirm}
                onChange={(e) => {
                    user.isConfirm = e.target.checked
                    setUsers()
                }} />
        </div>
        
        <div className={styleClasses.btn}>
            <MyButton onClick={() => updateUser(user) }>Сохранить</MyButton>
        </div>

    </div>)
}
