import { useEffect, useState } from "react"
import { MyButton } from "../UI/MyButton/MyButton"
import styleClasses from "./NewsList.module.css"
import Api from "../../api/Api"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const NewsList = () => {
    const [newsList, setNewsList] = useState([])

   

    useEffect(() => {
        Api.getNews().then(setNewsList).catch(console.error)
    }, [])

    return (<div className={styleClasses.container}>
        {
            newsList.map(news => {
                return(
                <div key={news.id}  className={styleClasses.news}>
                    <div className={styleClasses.title}>
                        {news.title}
                    </div>
                    <div className={styleClasses.content}>
                        <div dangerouslySetInnerHTML={{ __html: news.content }} />
                    </div>
                    <div className={styleClasses.keyboard}>
                        {/* <MyButton>Изменить</MyButton> */}
                        <MyButton onClick={() => {
                            Api.deleteNews(news.id).then(() => {
                                setTimeout(() =>
                                Api.getNews().then(setNewsList).catch(console.error),
                                100)}).catch(console.error)
                        }}>Удалить</MyButton>
                    </div>
                </div>
            )
            })
            
        }
    </div>)
}
