import styleClasses from "./ReferralUserListItem.module.css"
import { formatDate } from "../../utils/date"
import { useState } from "react"

export const ReferralUserListItem = ({ user }) => {
    const [isOpened, setIsOpened] = useState(false)
    return (
        <div className={styleClasses.item}>
            <div className={styleClasses.name} onClick={()=>{setIsOpened(!isOpened)}}>
                {user.id}. {user.name} / {user.username} - {user.countReferrals} польз.
            </div>
            {
                isOpened ?
                    <div className={styleClasses.users}>
                        {
                            user.invitedUsers.map((iUser) => (
                                <div className={styleClasses.user}>
                                    <div className={styleClasses.userTitle}>
                                        {iUser.id}. {iUser.name} / {iUser.username}
                                    </div>
                                    <div className={styleClasses.field}>
                                        Дата подключения: {formatDate(iUser.createdAt)}
                                    {
                                        iUser?.allTariffs && iUser?.allTariffs.length ?
                                            <>
                                            <div className="tariff">
                                                Тарифы: {
                                                    iUser?.allTariffs?.map((ut) => ut?.tariff?.name).join(', ')
                                                }
                                            </div>
                                            {/* TODO: заменить на платежи */}
                                            <div className="money">
                                                Принес денег: {
                                                    iUser?.allTariffs?.reduce((p,c) => p + (c?.tariff?.price || 0), 0)
                                                }
                                            </div>
                                            </>
                                        : <div className="tariff">Тарифов нет</div>
                                    }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                : null
            }
        </div>
    )
}
