import { FeaturesStatistics } from "../FeaturesStatistics/FeaturesStatistics"
import { PaymentsStatistics } from "../PaymentsStatistics/PaymentsStatistics"
import { UsersStatistics } from "../UsersStatistics/UsersStatistics"
import styleClasses from "./StatisticsPage.module.css"

export const StatisticsPage = () => {
    return (<div className={styleClasses.container}>
        <UsersStatistics />
        <FeaturesStatistics />
        <PaymentsStatistics />
    </div>)
}
