import { useEffect, useState } from "react"
import styleClasses from "./PaymentList.module.css"
import Api from "../../api/Api"
import { formatDate } from "../../utils/date"

export const PaymentList = () => {
    const [payments, setPayments] = useState([])

    useEffect(() => {
        Api.getPayments().then(setPayments).catch(console.error)
    }, [])

    function getTypeById(typeID) {
        switch (typeID) {
            case 1:
                return "CryptoCloud"
            case 0:
                return "YooMoney"
            default:
                return ""
        }
    }

    function getStatusById(typeID) {
        switch (typeID) {
            case 1:
                return "Выполнен"
            case 0:
                return "Инициализ..."
            default:
                return ""
        }
    }

    return (<div className={styleClasses.container}>
                <div className={styleClasses.title}>Платежи</div>
            <div className={styleClasses.list}>
                <table>
                    <tr className={styleClasses.titleRow}>
                        <td className={styleClasses.cell}>ID</td>
                        <td className={styleClasses.cell}>Сумма</td>
                        <td className={styleClasses.cell}>Статус</td>
                        <td className={styleClasses.cell}>Внешний ID</td>
                        <td className={styleClasses.cell}>Тип</td>
                        <td className={styleClasses.cell}>Дата</td>
                        <td className={styleClasses.cell}>Пользователь</td>
                    </tr>
                    {
                        payments.map((p) => (
                            <tr>
                                <td className={styleClasses.cell}>{p.id}</td>
                                <td className={styleClasses.cell}>{p.amount}</td>
                                <td className={styleClasses.cell}>{getStatusById(p.statusId)}</td>
                                <td className={styleClasses.cell}>{p.externalID}</td>
                                <td className={styleClasses.cell}>{getTypeById(p.typeId)}</td>
                                <td className={styleClasses.cell}>{formatDate(p.createdAt)}</td>
                                <td className={styleClasses.cell}>{p?.user?.id}. {p?.user?.name} / {p?.user?.username}</td>
                            </tr>
                        ))
                    }
                </table>
            </div>
    </div>)
}
