import './App.css';
import Router from './router/Router';
import '@coreui/coreui/dist/css/coreui.min.css'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

function App() {
  return (
    <div className="App">
      <Router/>
    </div>
  );
}

export default App;
