import { useState } from "react"
import { Menu } from "../components/Menu/Menu"
import { Humburger } from "./Humburger"
import styleClasses from "./MainLayout.module.css"

export const MainLayout = ({ children }) => {
    const [isActiveMenu, setIsActiveMenu] = useState(false)
    const classes = [styleClasses.menu]
    if (isActiveMenu) {
        classes.push(styleClasses.active)
    }

    return (<div className={styleClasses.window}>
        <header className={styleClasses.header}>
            <Humburger isActive={isActiveMenu} onClick={()=>setIsActiveMenu(!isActiveMenu)}/>
            <div className={styleClasses.title}>AviGroup</div>
        </header>
        <div className={styleClasses.container}>
            <div className={classes.join(' ')}>
                <Menu onCloseMenu={() => {setIsActiveMenu(false)}} />
            </div>
            <div className={styleClasses.content}>
                {children}
            </div>
        </div>
    </div>)
}